<template>
  <!--begin::Signup-->
  <div class="login-form login-signup">
    <form class="form" novalidate="novalidate" id="kt_login_signup_form">
      <div class="pb-13 pt-lg-0 pt-5">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          {{ $t("PAGES.LOGIN.SIGNUP.TITLE") }}
        </h3>
        <p class="text-muted font-weight-bold font-size-h4">
          {{ $t("PAGES.LOGIN.SIGNUP.SUBTITLE") }}
        </p>
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          :type="$t('FORM.NAME.TYPE')"
          :placeholder="$t('FORM.NAME.PLACEHOLDER')"
          name="fullname"
          ref="rfullname"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          :type="$t('FORM.WEBSITE.TYPE')"
          :placeholder="$t('FORM.WEBSITE.PLACEHOLDER')"
          name="website"
          ref="rwebsite"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          :type="$t('FORM.EMAIL.TYPE')"
          :placeholder="$t('FORM.EMAIL.PLACEHOLDER')"
          name="email"
          ref="remail"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          :type="$t('FORM.PASSWORD.TYPE')"
          :placeholder="$t('FORM.PASSWORD.PLACEHOLDER')"
          name="password"
          ref="rpassword"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          :type="$t('FORM.CONFIRM_PASSWORD.TYPE')"
          :placeholder="$t('FORM.CONFIRM_PASSWORD.PLACEHOLDER')"
          name="cpassword"
          ref="cpassword"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <label class="checkbox mb-0">
          <input type="checkbox" name="agree" ref="ragree" />
          <span class="mr-2"></span>
          {{ $t("PAGES.LOGIN.SIGNUP.AGREE") }}
          <a href="#" class="ml-2">{{ $t("PAGES.LOGIN.SIGNUP.TERMS") }}</a
          >.
        </label>
      </div>
      <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
        <button
          ref="kt_login_signup_submit"
          class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
          style="width:150px;"
        >
          {{ $t("PAGES.LOGIN.SIGNUP.SUBMIT") }}
        </button>
        <button
          type="button"
          id="kt_login_signup_cancel"
          class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
          @click="showForm('signin')"
        >
          {{ $t("PAGES.LOGIN.SIGNUP.CANCEL") }}
        </button>
      </div>
    </form>
  </div>
  <!--end::Signup-->
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";
import { LOGOUT, REGISTER } from "@/core/services/store/auth.module";

export default {
  name: "signup",
  props: ["showForm"],
  data() {
    return {
      form: {}
    };
  },
  mounted() {
    const signup_form = KTUtil.getById("kt_login_signup_form");
    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: this.$t("FORM.NAME.ERROR.EMPTY")
            }
          }
        },
        website: {
          validators: {
            notEmpty: {
              message: this.$t("FORM.WEBSITE.ERROR.EMPTY")
            },
            uri: {
              message: this.$t("FORM.WEBSITE.ERROR.URI")
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: this.$t("FORM.EMAIL.ERROR.EMPTY")
            },
            emailAddress: {
              message: this.$t("FORM.EMAIL.ERROR.VALID")
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: this.$t("FORM.PASSWORD.ERROR.EMPTY")
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: this.$t("FORM.CONFIRM_PASSWORD.ERROR.EMPTY")
            },
            identical: {
              compare: function() {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: this.$t("FORM.CONFIRM_PASSWORD.ERROR.NOT_SAME")
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: this.$t("FORM.AGREE.ERROR.EMPTY")
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
    this.fv1.on("core.form.valid", () => {
      const name = this.$refs.rfullname.value;
      const website = this.$refs.rwebsite.value;
      const email = this.$refs.remail.value;
      const password = this.$refs.rpassword.value;
      const agree = this.$refs.ragree.value;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay

      // send register request
      this.$store
        .dispatch(REGISTER, {
          name,
          website,
          email,
          password,
          agree
        })
        .then(res => {
          console.log("REGISTRY RES", res);
          console.log("STORE", this.$store.getters);
          this.$router.push({ name: "dashboard" });
        })
        .catch(err => {
          Swal.fire(this.$t(`ALERTS.${err.message}`));
        });

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    });

    this.fv1.on("core.form.invalid", () => {
      Swal.fire(this.$t("ALERTS.INVALID_FORM"));
    });
  }
};
</script>

<style></style>
