<template>
  <!--begin::Signin-->
  <div class="login-form login-signin">
    <form class="form" novalidate="novalidate" id="kt_login_signin_form">
      <div class="pb-13 pt-lg-0 pt-5">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          {{ $t("PAGES.LOGIN.SIGNIN.TITLE") }}
        </h3>
        <span class="text-muted font-weight-bold font-size-h4">
          {{ $t("PAGES.LOGIN.SIGNIN.CALL_CREATE_ACCOUNT") }}
          <a
            id="kt_login_signup"
            class="text-primary font-weight-bolder"
            @click="showForm('signup')"
          >
            {{ $t("PAGES.LOGIN.SIGNIN.CREATE_ACCOUNT") }}
          </a>
        </span>
      </div>
      <div class="form-group">
        <label class="font-size-h6 font-weight-bolder text-dark">
          {{ $t("FORM.EMAIL.TITLE") }}
        </label>
        <div id="example-input-group-1" label="" label-for="example-input-1">
          <input
            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
            :type="$t('FORM.EMAIL.TYPE')"
            name="email"
            ref="email"
            autocomplete="off"
            v-model="form.email"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between mt-n5">
          <label class="font-size-h6 font-weight-bolder text-dark pt-5">
            {{ $t("FORM.PASSWORD.TITLE") }}
          </label>
          <!-- <a
            class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
            id="kt_login_forgot"
            @click="showForm('forgot')"
          >
            {{ $t("PAGES.LOGIN.SIGNIN.FORGOT_PASSWORD") }}
          </a> -->
        </div>
        <div id="example-input-group-2" label="" label-for="example-input-2">
          <input
            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
            :type="$t('FORM.PASSWORD.TYPE')"
            name="password"
            ref="password"
            v-model="form.password"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="pb-lg-0 pb-5">
        <button
          ref="kt_login_signin_submit"
          class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
        >
          {{ $t("PAGES.LOGIN.SIGNIN.SUBMIT") }}
        </button>
      </div>
    </form>
  </div>
  <!--end::Signin-->
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "signin",
  props: ["showForm"],
  data() {
    return {
      form: {}
    };
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: this.$t("FORM.EMAIL.ERROR.EMPTY")
            },
            emailAddress: {
              message: this.$t("FORM.EMAIL.ERROR.VALID")
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: this.$t("FORM.PASSWORD.ERROR.EMPTY")
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
    this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      // send login request
      this.$store
        .dispatch(LOGIN, {
          email,
          password
        })
        // go to which page after successfully login
        .then(() =>
          this.$router.push({
            name: "dashboard"
          })
        )
        .catch(err => {
          Swal.fire(this.$t(`ALERTS.${err.message}`));
        });

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire(this.$t("ALERTS.INVALID_FORM"));
    });
  }
};
</script>

<style>
#kt_login_signup,
#kt_login_forgot {
  cursor: pointer;
}
</style>
